import { getNumber } from "../../helpers/dataUtils";
import serverCheck from "../../modules/serverCheck";
import BaseService from "../BaseService";

class FinancialConfigService extends BaseService {
  baseUrl = `${serverCheck.getServer().serverAdministration}financial-config`;
  cacheOptions = {
    prefix: "financial-config",
    ttl: 1 // 1 minute
  };
  clearCurrentCache() {}

  async current() {
    const res = await this.httpClient.get("/current");
    this.clearCurrentCache = res.clearCache;
    return res.data.data;
  }

  async historical() {
    const res = await this.httpClient.get("/historical", {
      noCache: true
    });
    return res.data.data;
  }

  async create({ flatRateFee, financialRate, aJobFlatFee }) {
    this.clearCurrentCache();
    const res = await this.httpClient.post(
      "/",
      {
        flatRateFee: getNumber(flatRateFee),
        financialRate: getNumber(financialRate),
        aJobFlatFee: getNumber(aJobFlatFee, 0)
      },
      {
        noCache: true
      }
    );
    return res.data.data;
  }
}

const financialConfigService = new FinancialConfigService();

export default financialConfigService;
